export const SERVICE_DATA = {
  sectionTitle: {
    title: "Piloz Great Features",
    text: "Our App Feature Lists"
  },
  posts: [
    {
      extraClassName: "",
      icon: "piloz-gear",
      title: "安全认证",
      text: "信息安全管理体系"
    },
    {
      extraClassName: "bg-2",
      icon: "piloz-shield",
      title: "隐私安全",
      text: "采用RSA等加密方式"
    },
    {
      extraClassName: "bg-3",
      icon: "piloz-user",
      title: "极速查询",
      text: "一键生成报告"
    },
    {
      extraClassName: "bg-4",
      icon: "piloz-writing",
      title: "解读建议",
      text: "专业解读个人信用报告"
    },
    {
      extraClassName: "",
      icon: "piloz-linked",
      title: "多维数据",
      text: "覆盖130+项模块数据"
    }
  ]
};

export const CALL_TO_ACTION_DATA = {
  sectionTitle: {
    text: "Business",
    title: "业务介绍"
  },
  paragraph: "信查查，您身边的信用管理专家。我们与多家权威信用数据机构紧密合作，为您呈现准确、权威且全面的个人信用报告。只需简单几步操作，即可快速获取您的信用状况，无论是贷款申请、租房需求还是日常信用管理，我们都能成为您的得力助手。我们深知隐私安全的重要性，采用先进加密技术，确保您的个人信息万无一失。同时，我们提供专业的信用解读与建议，帮助您更好地理解信用报告，规划未来财务生活。选择信查查，让信用成为您人生路上的坚实后盾！\n",
  posts: [
    // {
    //   title: "Responsive \n Design",
    //   extraClassName: " ",
    //   report-icon: "piloz-devices"
    // },
    // {
    //   title: "Online \n Marketing",
    //   report-icon: "piloz-networking",
    //   extraClassName: "color-2"
    // }
  ],
  button: {
    link: "#",
    label: "Discover More"
  }
};

import videoBG from "@images/resources/video-bg-1-1.jpg";

export const VIDEO_DATA = {
  title: "Watch Our Video",
  image: videoBG,
  videoID: "Kl5B6MBAntI"
};

import appFeature1 from "@images/resources/cta-2-moc-1.png";

export const APP_FEATURE_DATA = {
  sectionTitle: {
    title: "关于我们",
    text: "About Us"
  },
  image: appFeature1,
  paragraph: "信查查，一个专注于提供个人信用报告查询服务的在线平台，致力于为用户提供准确、权威且符合法律法规要求的个人信用报告查询服务。我们深知信用在现代社会中的重要性，因此，与多家权威信用数据机构建立了长期稳定的合作关系，确保每一份信用报告都能真实反映用户的信用状况。在数据安全与隐私保护方面，信查查不遗余力。我们采用国际先进的数据加密技术，结合严格的信息管理流程，构建起多重防护网，确保用户的个人信息在传输、存储及处理的每一个环节都得到最严密的保护。我们深知，用户的信任是我们最宝贵的财富，因此，我们承诺将始终坚守隐私保护的底线，让用户在使用我们的服务时能够安心无忧。",
  options: [
    {
      label: "Refresing to get such a personal touch."
    },
    {
      label: "Duis aute irure dolor in reprehenderit in voluptate."
    },
    {
      label: "Velit esse cillum dolore eu fugiat nulla pariatur."
    }
  ],
  button: { link: "#", label: "Discover More" }
};

export const PRICING_DATA = {
  sectionTitle: {
    title: "关于我们",
    text: "About Us"
  },
  paragraph: "信查查，一个专注于提供个人信用报告查询服务的在线平台，致力于为用户提供准确、权威且符合法律法规要求的个人信用报告查询服务。我们深知信用在现代社会中的重要性，因此，与多家权威信用数据机构建立了长期稳定的合作关系，确保每一份信用报告都能真实反映用户的信用状况。在数据安全与隐私保护方面，信查查不遗余力。我们采用国际先进的数据加密技术，结合严格的信息管理流程，构建起多重防护网，确保用户的个人信息在传输、存储及处理的每一个环节都得到最严密的保护。我们深知，用户的信任是我们最宝贵的财富，因此，我们承诺将始终坚守隐私保护的底线，让用户在使用我们的服务时能够安心无忧。",
  monthly: [
    // {
    //   count: "01",
    //   amount: "$20.00",
    //   name: "Basic Pack",
    //   extraClassName: "color-1",
    //   options: [
    //     { label: "Extra features" },
    //     { label: "Lifetime free support" },
    //     { label: "Upgrate options" },
    //     { label: "Full access" }
    //   ],
    //   button: {
    //     label: "Choose Plan",
    //     link: "#"
    //   }
    // },
    // {
    //   count: "02",
    //   amount: "$30.00",
    //   name: "Basic Pack",
    //   extraClassName: "color-2",
    //   options: [
    //     { label: "Extra features" },
    //     { label: "Lifetime free support" },
    //     { label: "Upgrate options" },
    //     { label: "Full access" }
    //   ],
    //   button: {
    //     label: "Choose Plan",
    //     link: "#"
    //   }
    // },
    // {
    //   count: "03",
    //   amount: "$60.00",
    //   name: "Basic Pack",
    //   extraClassName: "color-3",
    //   options: [
    //     { label: "Extra features" },
    //     { label: "Lifetime free support" },
    //     { label: "Upgrate options" },
    //     { label: "Full access" }
    //   ],
    //   button: {
    //     label: "Choose Plan",
    //     link: "#"
    //   }
    // }
  ],
  yearly: [
    {
      count: "01",
      amount: "$40.00",
      name: "Premium Pack",
      extraClassName: "color-1",
      options: [
        { label: "Extra features" },
        { label: "Lifetime free support" },
        { label: "Upgrate options" },
        { label: "Full access" }
      ],
      button: {
        label: "Choose Plan",
        link: "#"
      }
    },
    {
      count: "02",
      amount: "$80.00",
      name: "Premium Pack",
      extraClassName: "color-2",
      options: [
        { label: "Extra features" },
        { label: "Lifetime free support" },
        { label: "Upgrate options" },
        { label: "Full access" }
      ],
      button: {
        label: "Choose Plan",
        link: "#"
      }
    },
    {
      count: "03",
      amount: "$100.00",
      name: "Premium Pack",
      extraClassName: "color-3",
      options: [
        { label: "Extra features" },
        { label: "Lifetime free support" },
        { label: "Upgrate options" },
        { label: "Full access" }
      ],
      button: {
        label: "Choose Plan",
        link: "#"
      }
    }
  ]
};

import testimonial1 from "@images/testimonials/testimonials-1-img-1.png";
import testimonial2 from "@images/testimonials/testimonials-1-img-2.png";
import testimonialShape from "@images/testimonials/testimonials-1-shape-1.png";
import testimonialShape2 from "@images/testimonials/testimonials-1-shape-2.png";

export const TESTIMONIALS_DATA = {
  sectionTitle: {
    text: "Our Testimonials",
    title: "What They Says"
  },
  posts: [
    {
      extraClassName: " ",
      shape: testimonialShape,
      image: testimonial1,
      title: "Isabel Griffith",
      designation: "Customer",
      text:
        "This is due to their excellent service, competitive pricing and customer support. It’s throughly refresing to get such a personal touch."
    },
    {
      extraClassName: "color-2",
      image: testimonial2,
      shape: testimonialShape2,
      title: "Wesley Dixon",
      designation: "Customer",
      text:
        "This is due to their excellent service, competitive pricing and customer support. It’s throughly refresing to get such a personal touch."
    },
    {
      extraClassName: " ",
      image: testimonial1,
      shape: testimonialShape,
      title: "Isabel Griffith",
      designation: "Customer",
      text:
        "This is due to their excellent service, competitive pricing and customer support. It’s throughly refresing to get such a personal touch."
    },
    {
      extraClassName: "color-2",
      image: testimonial2,
      shape: testimonialShape2,
      title: "Wesley Dixon",
      designation: "Customer",
      text:
        "This is due to their excellent service, competitive pricing and customer support. It’s throughly refresing to get such a personal touch."
    },
    {
      extraClassName: " ",
      image: testimonial1,
      shape: testimonialShape,
      title: "Isabel Griffith",
      designation: "Customer",
      text:
        "This is due to their excellent service, competitive pricing and customer support. It’s throughly refresing to get such a personal touch."
    },
    {
      extraClassName: "color-2",
      image: testimonial2,
      shape: testimonialShape2,
      title: "Wesley Dixon",
      designation: "Customer",
      text:
        "This is due to their excellent service, competitive pricing and customer support. It’s throughly refresing to get such a personal touch."
    }
  ]
};

import appshot1 from "@images/app-shots/app-shot-n-1-1.png";
import appshot2 from "@images/app-shots/app-shot-n-1-2.png";
import appshot3 from "@images/app-shots/app-shot-n-1-3.png";
import appshot4 from "@images/app-shots/app-shot-n-1-4.png";
import appshot5 from "@images/app-shots/app-shot-n-1-5.png";

export const APP_SHOT_DATA = {
  sectionTitle: {
    title: "App Screenshots",
    text: "Check Before Choosing"
  },
  posts: [
    {
      image: appshot1
    },
    {
      image: appshot2
    },
    {
      image: appshot3
    },
    {
      image: appshot4
    },
    {
      image: appshot5
    },
    {
      image: appshot1
    },
    {
      image: appshot2
    },
    {
      image: appshot3
    },
    {
      image: appshot4
    },
    {
      image: appshot5
    },
    {
      image: appshot1
    },
    {
      image: appshot2
    },
    {
      image: appshot3
    },
    {
      image: appshot4
    },
    {
      image: appshot5
    }
  ]
};

export const FAQ_DATA = {
  sectionTitle: {
    text: "Frequently Asked Questions",
    title: "Have Any Question?"
  },
  posts: [
    {
      status: true,
      title: "Pre and post launch mobile app marketing pitfalls to avoid",
      text:
        "There are many variations of passages of available but majority have alteration in some by inject humour or random words. Lorem ipsum dolor sit amet, error insolens reprimique no quo, ea pri verterem phaedr vel ea iisque aliquam."
    },
    {
      status: false,
      title: "Boostup your application traffic is just a step away",
      text:
        "There are many variations of passages of available but majority have alteration in some by inject humour or random words. Lorem ipsum dolor sit amet, error insolens reprimique no quo, ea pri verterem phaedr vel ea iisque aliquam."
    },
    {
      status: false,
      title: "How to update application new features",
      text:
        "There are many variations of passages of available but majority have alteration in some by inject humour or random words. Lorem ipsum dolor sit amet, error insolens reprimique no quo, ea pri verterem phaedr vel ea iisque aliquam."
    },
    {
      status: false,
      title: "How to connect with the support to improve app experience",
      text:
        "There are many variations of passages of available but majority have alteration in some by inject humour or random words. Lorem ipsum dolor sit amet, error insolens reprimique no quo, ea pri verterem phaedr vel ea iisque aliquam."
    }
  ]
};

import blog1 from "@images/blog/blog-2-1.jpg";
import blog2 from "@images/blog/blog-2-2.jpg";
import blog3 from "@images/blog/blog-2-3.jpg";
import blog4 from "@images/blog/blog-2-4.jpg";
import blog5 from "@images/blog/blog-2-5.jpg";
import blog6 from "@images/blog/blog-2-6.jpg";

export const BLOG_DATA = {
  sectionTitle: {
    text: "From the Blog Posts",
    title: "News & Articles"
  },
  posts: [
    {
      image: blog1,
      date: "20 May, 2020",
      commentCount: "2",
      title: "Leverage agile frame works to",
      text:
        "There are many people variation of passages of lorem Ipsum available in the majority sed do eius.",
      link: {
        path: "/blog-details",
        label: "Read More"
      }
    },
    {
      image: blog2,
      date: "20 May, 2020",
      commentCount: "2",
      title: "Launch New Mobile Marketing Pitfalls",
      text:
        "There are many people variation of passages of lorem Ipsum available in the majority sed do eius.",
      link: {
        path: "/blog-details",
        label: "Read More"
      }
    },
    {
      image: blog3,
      date: "20 May, 2020",
      commentCount: "2",
      title: "Bring to the table win-win survival",
      text:
        "There are many people variation of passages of lorem Ipsum available in the majority sed do eius.",
      link: {
        path: "/blog-details",
        label: "Read More"
      }
    },
    {
      image: blog4,
      date: "20 May, 2020",
      commentCount: "2",
      title: "Capitalize on low hanging fruit to",
      text:
        "There are many people variation of passages of lorem Ipsum available in the majority sed do eius.",
      link: {
        path: "/blog-details",
        label: "Read More"
      }
    },
    {
      image: blog5,
      date: "20 May, 2020",
      commentCount: "2",
      title: "Collaboratively admin istrate via ",
      text:
        "There are many people variation of passages of lorem Ipsum available in the majority sed do eius.",
      link: {
        path: "/blog-details",
        label: "Read More"
      }
    },
    {
      image: blog6,
      date: "20 May, 2020",
      commentCount: "2",
      title: "Efficiently unleash cross-media ",
      text:
        "There are many people variation of passages of lorem Ipsum available in the majority sed do eius.",
      link: {
        path: "/blog-details",
        label: "Read More"
      }
    }
  ]
};
